/**
CREATIVE CODING
---
Kill server : CTRL + C
Start server : npm run start
Start secure server : npm run start-https
Final build : npm run build
---
To generate new certificate for https connection with external device run :
#sh
mkcert 0.0.0.0 localhost 127.0.0.1 yourLocalIP ::1
mv 0.0.0.0+4-key.pem certificate.key
mv 0.0.0.0+4.pem certificate.cert
**/

// import Playground from "@onemorestudio/playgroundjs";
import { isMobile } from "mobile-device-detect";
import {
  Renderer,
  Camera,
  RenderTarget,
  Geometry,
  Program,
  Mesh,
  Color,
  Vec2,
  Box,
  NormalProgram,
  Post,
} from "./src/index.js";
export default class Background {
  constructor() {
    this.isMobile = isMobile;

    this.fragment = /* glsl */ `
                precision highp float;

                uniform sampler2D tMap;
                uniform sampler2D tFluid;
                uniform float uTime;
                varying vec2 vUv;

                void main() {
                    vec3 fluid = texture2D(tFluid, vUv).rgb;
                    vec2 uv = vUv - fluid.rg * 0.0002;

                    gl_FragColor = mix( texture2D(tMap, uv), vec4(fluid * 0.1 + 0.0, 1), step(0.0, vUv.x) ) ;

                    // Oscillate between fluid values and the distorted scene
                    //  gl_FragColor = mix(texture2D(tMap, uv), vec4(fluid * 0.1 + 0.5, 1), smoothstep(0.0, 0.7, sin(uTime)));
                }
            `;

    this.baseVertex = /* glsl */ `
                precision highp float;
                attribute vec2 position;
                attribute vec2 uv;
                varying vec2 vUv;
                varying vec2 vL;
                varying vec2 vR;
                varying vec2 vT;
                varying vec2 vB;
                uniform vec2 texelSize;
                void main () {
                    vUv = uv;
                    vL = vUv - vec2(texelSize.x, 0.0);
                    vR = vUv + vec2(texelSize.x, 0.0);
                    vT = vUv + vec2(0.0, texelSize.y);
                    vB = vUv - vec2(0.0, texelSize.y);
                    gl_Position = vec4(position, 0, 1);
                }
            `;

    this.clearShader = /* glsl */ `
                precision mediump float;
                precision mediump sampler2D;
                varying highp vec2 vUv;
                uniform sampler2D uTexture;
                uniform float value;
                void main () {
                    gl_FragColor = value * texture2D(uTexture, vUv);
                }
            `;

    this.splatShader = /* glsl */ `
                precision highp float;
                precision highp sampler2D;
                varying vec2 vUv;
                uniform sampler2D uTarget;
                uniform float aspectRatio;
                uniform vec3 color;
                uniform vec2 point;
                uniform float radius;
                void main () {
                    vec2 p = vUv - point.xy;
                    p.x *= aspectRatio;
                    vec3 splat = exp(-dot(p, p) / radius) * color;
                    vec3 base = texture2D(uTarget, vUv).xyz;
                    gl_FragColor = vec4(base + splat, 1.0);
                }
            `;

    this.advectionManualFilteringShader = /* glsl */ `
                precision highp float;
                precision highp sampler2D;
                varying vec2 vUv;
                uniform sampler2D uVelocity;
                uniform sampler2D uSource;
                uniform vec2 texelSize;
                uniform vec2 dyeTexelSize;
                uniform float dt;
                uniform float dissipation;
                vec4 bilerp (sampler2D sam, vec2 uv, vec2 tsize) {
                    vec2 st = uv / tsize - 0.5;
                    vec2 iuv = floor(st);
                    vec2 fuv = fract(st);
                    vec4 a = texture2D(sam, (iuv + vec2(0.5, 0.5)) * tsize);
                    vec4 b = texture2D(sam, (iuv + vec2(1.5, 0.5)) * tsize);
                    vec4 c = texture2D(sam, (iuv + vec2(0.5, 1.5)) * tsize);
                    vec4 d = texture2D(sam, (iuv + vec2(1.5, 1.5)) * tsize);
                    return mix(mix(a, b, fuv.x), mix(c, d, fuv.x), fuv.y);
                }
                void main () {
                    vec2 coord = vUv - dt * bilerp(uVelocity, vUv, texelSize).xy * texelSize;
                    gl_FragColor = dissipation * bilerp(uSource, coord, dyeTexelSize);
                    gl_FragColor.a = 1.0;
                }
            `;

    this.advectionShader = /* glsl */ `
                precision highp float;
                precision highp sampler2D;
                varying vec2 vUv;
                uniform sampler2D uVelocity;
                uniform sampler2D uSource;
                uniform vec2 texelSize;
                uniform float dt;
                uniform float dissipation;
                void main () {
                    vec2 coord = vUv - dt * texture2D(uVelocity, vUv).xy * texelSize;
                    gl_FragColor = dissipation * texture2D(uSource, coord);
                    gl_FragColor.a = 1.0;
                }
            `;

    this.divergenceShader = /* glsl */ `
                precision mediump float;
                precision mediump sampler2D;
                varying highp vec2 vUv;
                varying highp vec2 vL;
                varying highp vec2 vR;
                varying highp vec2 vT;
                varying highp vec2 vB;
                uniform sampler2D uVelocity;
                void main () {
                    float L = texture2D(uVelocity, vL).x;
                    float R = texture2D(uVelocity, vR).x;
                    float T = texture2D(uVelocity, vT).y;
                    float B = texture2D(uVelocity, vB).y;
                    vec2 C = texture2D(uVelocity, vUv).xy;
                    if (vL.x < 0.0) { L = -C.x; }
                    if (vR.x > 1.0) { R = -C.x; }
                    if (vT.y > 1.0) { T = -C.y; }
                    if (vB.y < 0.0) { B = -C.y; }
                    float div = 0.5 * (R - L + T - B);
                    gl_FragColor = vec4(div, 0.0, 0.0, 1.0);
                }
            `;

    this.curlShader = /* glsl */ `
                precision mediump float;
                precision mediump sampler2D;
                varying highp vec2 vUv;
                varying highp vec2 vL;
                varying highp vec2 vR;
                varying highp vec2 vT;
                varying highp vec2 vB;
                uniform sampler2D uVelocity;
                void main () {
                    float L = texture2D(uVelocity, vL).y;
                    float R = texture2D(uVelocity, vR).y;
                    float T = texture2D(uVelocity, vT).x;
                    float B = texture2D(uVelocity, vB).x;
                    float vorticity = R - L - T + B;
                    gl_FragColor = vec4(0.5 * vorticity, 0.0, 0.0, 1.0);
                }
            `;

    this.vorticityShader = /* glsl */ `
                precision highp float;
                precision highp sampler2D;
                varying vec2 vUv;
                varying vec2 vL;
                varying vec2 vR;
                varying vec2 vT;
                varying vec2 vB;
                uniform sampler2D uVelocity;
                uniform sampler2D uCurl;
                uniform float curl;
                uniform float dt;
                void main () {
                    float L = texture2D(uCurl, vL).x;
                    float R = texture2D(uCurl, vR).x;
                    float T = texture2D(uCurl, vT).x;
                    float B = texture2D(uCurl, vB).x;
                    float C = texture2D(uCurl, vUv).x;
                    vec2 force = 0.5 * vec2(abs(T) - abs(B), abs(R) - abs(L));
                    force /= length(force) + 0.0001;
                    force *= curl * C;
                    force.y *= -1.0;
                    vec2 vel = texture2D(uVelocity, vUv).xy;
                    gl_FragColor = vec4(vel + force * dt, 0.0, 1.0);
                }
            `;

    this.pressureShader = /* glsl */ `
                precision mediump float;
                precision mediump sampler2D;
                varying highp vec2 vUv;
                varying highp vec2 vL;
                varying highp vec2 vR;
                varying highp vec2 vT;
                varying highp vec2 vB;
                uniform sampler2D uPressure;
                uniform sampler2D uDivergence;
                void main () {
                    float L = texture2D(uPressure, vL).x;
                    float R = texture2D(uPressure, vR).x;
                    float T = texture2D(uPressure, vT).x;
                    float B = texture2D(uPressure, vB).x;
                    float C = texture2D(uPressure, vUv).x;
                    float divergence = texture2D(uDivergence, vUv).x;
                    float pressure = (L + R + B + T - divergence) * 0.25;
                    gl_FragColor = vec4(pressure, 0.0, 0.0, 1.0);
                }
            `;

    this.gradientSubtractShader = /* glsl */ `
                precision mediump float;
                precision mediump sampler2D;
                varying highp vec2 vUv;
                varying highp vec2 vL;
                varying highp vec2 vR;
                varying highp vec2 vT;
                varying highp vec2 vB;
                uniform sampler2D uPressure;
                uniform sampler2D uVelocity;
                void main () {
                    float L = texture2D(uPressure, vL).x;
                    float R = texture2D(uPressure, vR).x;
                    float T = texture2D(uPressure, vT).x;
                    float B = texture2D(uPressure, vB).x;
                    vec2 velocity = texture2D(uVelocity, vUv).xy;
                    velocity.xy -= vec2(R - L, T - B);
                    gl_FragColor = vec4(velocity, 0.0, 1.0);
                }
            `;

    // Create a new renderer
    this.renderer = new Renderer({ dpr: 2 });
    this.gl = this.renderer.gl;
    // document.body.appendChild(gl.canvas);

    this.canvas = document.createElement("canvas");
    // set pixel ratio
    this.canvas.width = window.innerWidth * 2;
    this.canvas.height = window.innerHeight * 2;
    this.canvas.style.width = window.innerWidth + "px";
    this.canvas.style.height = window.innerHeight + "px";
    this.ctx = this.canvas.getContext("2d");
    // set context pixel ratio

    // canvas.width = window.innerWidth;
    // canvas.height = window.innerHeight;
    document.body.appendChild(this.canvas);
    this.img = new Image();
    this.newWidth = 0;
    this.newHeight = 0;
    this.topleft = { x: 0, y: 0 };
    this.img.onload = () => {
      // change the dimension but keep the aspect ratio
      this.newWidth = window.innerWidth * 2;
      this.newHeight = (this.newWidth * this.img.height) / this.img.width;
      //   calculate the top left position for the image to be centered vertically
      this.topleft.x = 0;
      this.topleft.y = this.canvas.height / 2 - this.newHeight / 2;
    };
    this.img.src = this.isMobile
      ? window.innerWidth > window.innerHeight
        ? "./images/mask_green_desk.svg"
        : "./images/mask_green_mobile.svg"
      : "./images/mask_green_desk.svg";

    this.gl.clearColor(1, 1, 1, 1);

    this.camera = new Camera(this.gl, { fov: 35 });
    this.camera.position.set(0, 1, 5);
    this.camera.lookAt([0, 0, 0]);

    this.post = new Post(this.gl);
    window.addEventListener("resize", this.resize.bind(this), false);
    window.addEventListener(
      "orientationchange",
      this.changeOrientation.bind(this),
      false
    );
    this.resize();

    this.lastMouse = new Vec2();
    this.lastOrientation = new Vec2();
    this.splats = [];
    // Create handlers to get mouse position and velocity
    const isTouchCapable = "ontouchstart" in window;
    if (isTouchCapable) {
      window.addEventListener("touchstart", this.updateMouse.bind(this), false);
      window.addEventListener("touchmove", this.updateMouse.bind(this), false);
      window.addEventListener(
        "deviceorientation",
        this.updateDeviceOrientation.bind(this)
      );
    } else {
      window.addEventListener("mousemove", this.updateMouse.bind(this), false);
    }

    this.init();
  }

  init() {
    const gl = this.gl;
    // Resolution of simulation
    this.simRes = 128;
    this.dyeRes = 512;

    // Main inputs to control look and feel of fluid
    this.iterations = 3;
    this.densityDissipation = 0.995;
    this.velocityDissipation = 0.98;
    this.pressureDissipation = 0.8;
    this.curlStrength = 20;
    this.radius = 0.2;

    // Common uniform
    const texelSize = { value: new Vec2(1 / this.simRes) };

    // Get supported formats and types for FBOs
    let supportLinearFiltering =
      gl.renderer.extensions[
        `OES_texture_${gl.renderer.isWebgl2 ? `` : `half_`}float_linear`
      ];
    const halfFloat = gl.renderer.isWebgl2
      ? gl.HALF_FLOAT
      : gl.renderer.extensions["OES_texture_half_float"].HALF_FLOAT_OES;

    const filtering = supportLinearFiltering ? gl.LINEAR : gl.NEAREST;
    let rgba, rg, r;

    if (gl.renderer.isWebgl2) {
      rgba = this.getSupportedFormat(gl, gl.RGBA16F, gl.RGBA, halfFloat);
      rg = this.getSupportedFormat(gl, gl.RG16F, gl.RG, halfFloat);
      r = this.getSupportedFormat(gl, gl.R16F, gl.RED, halfFloat);
    } else {
      rgba = this.getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloat);
      rg = rgba;
      r = rgba;
    }

    // Create fluid simulation FBOs
    this.density = this.createDoubleFBO(gl, {
      width: this.dyeRes,
      height: this.dyeRes,
      type: halfFloat,
      format: rgba?.format,
      internalFormat: rgba?.internalFormat,
      minFilter: filtering,
      depth: false,
    });

    this.velocity = this.createDoubleFBO(gl, {
      width: this.simRes,
      height: this.simRes,
      type: halfFloat,
      format: rg?.format,
      internalFormat: rg?.internalFormat,
      minFilter: filtering,
      depth: false,
    });

    this.pressure = this.createDoubleFBO(gl, {
      width: this.simRes,
      height: this.simRes,
      type: halfFloat,
      format: r?.format,
      internalFormat: r?.internalFormat,
      minFilter: gl.NEAREST,
      depth: false,
    });

    this.divergence = new RenderTarget(gl, {
      width: this.simRes,
      height: this.simRes,
      type: halfFloat,
      format: r?.format,
      internalFormat: r?.internalFormat,
      minFilter: gl.NEAREST,
      depth: false,
    });

    this.curl = new RenderTarget(gl, {
      width: this.simRes,
      height: this.simRes,
      type: halfFloat,
      format: r?.format,
      internalFormat: r?.internalFormat,
      minFilter: gl.NEAREST,
      depth: false,
    });

    // Geometry to be used for the simulation programs
    const triangle = new Geometry(gl, {
      position: { size: 2, data: new Float32Array([-1, -1, 3, -1, -1, 3]) },
      uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) },
    });

    // Create fluid simulation programs
    this.clearProgram = new Mesh(gl, {
      geometry: triangle,
      program: new Program(gl, {
        vertex: this.baseVertex,
        fragment: this.clearShader,
        uniforms: {
          texelSize,
          uTexture: { value: null },
          value: { value: this.pressureDissipation },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });

    this.splatProgram = new Mesh(gl, {
      geometry: triangle,
      program: new Program(gl, {
        vertex: this.baseVertex,
        fragment: this.splatShader,
        uniforms: {
          texelSize,
          uTarget: { value: null },
          aspectRatio: { value: 1 },
          color: { value: new Color() },
          point: { value: new Vec2() },
          radius: { value: 1 },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });

    this.advectionProgram = new Mesh(gl, {
      geometry: triangle,
      program: new Program(gl, {
        vertex: this.baseVertex,
        fragment: this.supportLinearFiltering
          ? this.advectionShader
          : this.advectionManualFilteringShader,
        uniforms: {
          texelSize,
          dyeTexelSize: { value: new Vec2(1 / this.dyeRes, 1 / this.dyeRes) },
          uVelocity: { value: null },
          uSource: { value: null },
          dt: { value: 0.016 },
          dissipation: { value: 1.0 },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });

    this.divergenceProgram = new Mesh(gl, {
      geometry: triangle,
      program: new Program(gl, {
        vertex: this.baseVertex,
        fragment: this.divergenceShader,
        uniforms: {
          texelSize,
          uVelocity: { value: null },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });

    this.curlProgram = new Mesh(gl, {
      geometry: triangle,
      program: new Program(gl, {
        vertex: this.baseVertex,
        fragment: this.curlShader,
        uniforms: {
          texelSize,
          uVelocity: { value: null },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });

    this.vorticityProgram = new Mesh(gl, {
      geometry: triangle,
      program: new Program(gl, {
        vertex: this.baseVertex,
        fragment: this.vorticityShader,
        uniforms: {
          texelSize,
          uVelocity: { value: null },
          uCurl: { value: null },
          curl: { value: this.curlStrength },
          dt: { value: 0.016 },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });

    this.pressureProgram = new Mesh(gl, {
      geometry: triangle,
      program: new Program(gl, {
        vertex: this.baseVertex,
        fragment: this.pressureShader,
        uniforms: {
          texelSize,
          uPressure: { value: null },
          uDivergence: { value: null },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });

    this.gradienSubtractProgram = new Mesh(gl, {
      geometry: triangle,
      program: new Program(gl, {
        vertex: this.baseVertex,
        fragment: this.gradientSubtractShader,
        uniforms: {
          texelSize,
          uPressure: { value: null },
          uVelocity: { value: null },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });

    const geometry = new Box(gl);
    this.mesh = new Mesh(gl, { geometry, program: new NormalProgram(gl) });
    const fragment = this.fragment;
    this.pass = this.post.addPass({
      fragment,
      uniforms: {
        tFluid: { value: null },
        uTime: { value: 0 },
      },
    });

    requestAnimationFrame(this.update.bind(this));
  }

  resize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.camera.perspective({
      aspect: this.gl.canvas.width / this.gl.canvas.height,
    });
    this.post.resize();

    this.canvas.width = window.innerWidth * 2;
    this.canvas.height = window.innerHeight * 2;
    this.canvas.style.width = window.innerWidth + "px";
    this.canvas.style.height = window.innerHeight + "px";
    this.topleft.y = this.canvas.height / 2 - this.newHeight / 2;
    // change the dimension but keep the aspect ratio
    this.newWidth = window.innerWidth * 2;
    this.newHeight = (this.newWidth * this.img.height) / this.img.width;
  }

  changeOrientation(e) {
    setTimeout(() => {
      if (window.innerWidth > window.innerHeight && this.isMobile) {
        this.img.src = "./images/mask_green_desk.svg";
      } else {
        this.img.src = "./images/mask_green_mobile.svg";
      }
    }, 100);
  }

  getSupportedFormat(gl, internalFormat, format, type) {
    if (!this.supportRenderTextureFormat(gl, internalFormat, format, type)) {
      switch (internalFormat) {
        case gl.R16F:
          return this.getSupportedFormat(gl, gl.RG16F, gl.RG, type);
        case gl.RG16F:
          return this.getSupportedFormat(gl, gl.RGBA16F, gl.RGBA, type);
        default:
          return null;
      }
    }

    return { internalFormat, format };
  }

  supportRenderTextureFormat(gl, internalFormat, format, type) {
    let texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      internalFormat,
      4,
      4,
      0,
      format,
      type,
      null
    );

    let fbo = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      texture,
      0
    );

    const status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
    if (status != gl.FRAMEBUFFER_COMPLETE) return false;
    return true;
  }

  createDoubleFBO(
    gl,
    {
      width,
      height,
      wrapS,
      wrapT,
      minFilter = gl.LINEAR,
      magFilter = minFilter,
      type,
      format,
      internalFormat,
      depth,
    } = {}
  ) {
    const options = {
      width,
      height,
      wrapS,
      wrapT,
      minFilter,
      magFilter,
      type,
      format,
      internalFormat,
      depth,
    };
    const fbo = {
      read: new RenderTarget(gl, options),
      write: new RenderTarget(gl, options),
      swap: () => {
        let temp = fbo.read;
        fbo.read = fbo.write;
        fbo.write = temp;
      },
    };
    return fbo;
  }

  updateMouse(e) {
    e.preventDefault();
    if (e.changedTouches && e.changedTouches.length) {
      e.x = e.changedTouches[0].pageX;
      e.y = e.changedTouches[0].pageY;
    }
    if (e.x === undefined) {
      e.x = e.pageX;
      e.y = e.pageY;
    }

    if (!this.lastMouse.isInit) {
      this.lastMouse.isInit = true;

      // First input
      this.lastMouse.set(e.x, e.y);
    }

    const deltaX = e.x - this.lastMouse.x;
    const deltaY = e.y - this.lastMouse.y;

    this.lastMouse.set(e.x, e.y);

    // Add if the mouse is moving
    if (Math.abs(deltaX) || Math.abs(deltaY)) {
      this.splats.push({
        // Get mouse value in 0 to 1 range, with y flipped
        x: e.x / this.gl.renderer.width,
        y: 1.0 - e.y / this.gl.renderer.height,
        dx: deltaX * 5.0,
        dy: deltaY * -5.0,
      });
    }
  }

  updateDeviceOrientation(e) {
    e.preventDefault();

    let gamma = e.gamma || 0; // horizontal tilt, left to right
    let beta = -e.beta * 2 || 0; // vertical tilt, front to back

    if (window.innerWidth > window.innerHeight) {
      gamma = e.beta || 0;
      beta = e.gamma * 2 || 0;
    }

    if (!this.lastOrientation.isInit) {
      this.lastOrientation.isInit = true;

      // First input
      this.lastOrientation.set(gamma, beta);
    }

    const deltaGamma = gamma - this.lastOrientation.x;
    const deltaBeta = beta - this.lastOrientation.y;

    this.lastOrientation.set(gamma, beta);

    // console.log(this.lastOrientation.x, this.lastOrientation.y);

    // Add if the device is moving
    if (Math.abs(deltaGamma) || Math.abs(deltaBeta)) {
      this.splats.push({
        // Get device orientation values in 0 to 1 range
        x: (gamma + 90) / 180,
        y: (beta + 180) / 360,
        dx: deltaGamma * 5.0,
        dy: deltaBeta * -5.0,
      });
    }
  }

  splat({ x, y, dx, dy }) {
    const gl = this.gl;
    this.splatProgram.program.uniforms.uTarget.value =
      this.velocity.read.texture;
    this.splatProgram.program.uniforms.aspectRatio.value =
      gl.renderer.width / gl.renderer.height;
    this.splatProgram.program.uniforms.point.value.set(x, y);
    this.splatProgram.program.uniforms.color.value.set(dx, dx, 1.0);
    this.splatProgram.program.uniforms.radius.value = this.radius / 100.0;

    gl.renderer.render({
      scene: this.splatProgram,
      target: this.velocity.write,
      sort: false,
      update: false,
    });
    this.velocity.swap();

    this.splatProgram.program.uniforms.uTarget.value =
      this.density.read.texture;

    gl.renderer.render({
      scene: this.splatProgram,
      target: this.density.write,
      sort: false,
      update: false,
    });
    this.density.swap();
  }

  update(t) {
    requestAnimationFrame(this.update.bind(this));
    const gl = this.gl;
    this.topleft.y = this.canvas.height / 2 - this.newHeight / 2;

    // Perform all of the fluid simulation renders
    // No need to clear during sim, saving a number of GL calls.
    gl.renderer.autoClear = false;

    // Render all of the inputs since last frame
    for (let i = this.splats.length - 1; i >= 0; i--) {
      this.splat(this.splats.splice(i, 1)[0]);
    }

    this.curlProgram.program.uniforms.uVelocity.value =
      this.velocity.read.texture;

    gl.renderer.render({
      scene: this.curlProgram,
      target: this.curl,
      sort: false,
      update: false,
    });

    this.vorticityProgram.program.uniforms.uVelocity.value =
      this.velocity.read.texture;
    this.vorticityProgram.program.uniforms.uCurl.value = this.curl.texture;

    gl.renderer.render({
      scene: this.vorticityProgram,
      target: this.velocity.write,
      sort: false,
      update: false,
    });
    this.velocity.swap();

    this.divergenceProgram.program.uniforms.uVelocity.value =
      this.velocity.read.texture;

    gl.renderer.render({
      scene: this.divergenceProgram,
      target: this.divergence,
      sort: false,
      update: false,
    });

    this.clearProgram.program.uniforms.uTexture.value =
      this.pressure.read.texture;
    this.clearProgram.program.uniforms.value.value = this.pressureDissipation;

    gl.renderer.render({
      scene: this.clearProgram,
      target: this.pressure.write,
      sort: false,
      update: false,
    });
    this.pressure.swap();

    this.pressureProgram.program.uniforms.uDivergence.value =
      this.divergence.texture;

    for (let i = 0; i < this.iterations; i++) {
      this.pressureProgram.program.uniforms.uPressure.value =
        this.pressure.read.texture;

      gl.renderer.render({
        scene: this.pressureProgram,
        target: this.pressure.write,
        sort: false,
        update: false,
      });
      this.pressure.swap();
    }

    this.gradienSubtractProgram.program.uniforms.uPressure.value =
      this.pressure.read.texture;
    this.gradienSubtractProgram.program.uniforms.uVelocity.value =
      this.velocity.read.texture;

    gl.renderer.render({
      scene: this.gradienSubtractProgram,
      target: this.velocity.write,
      sort: false,
      update: false,
    });
    this.velocity.swap();

    this.advectionProgram.program.uniforms.dyeTexelSize.value.set(
      1 / this.simRes
    );
    this.advectionProgram.program.uniforms.uVelocity.value =
      this.velocity.read.texture;
    this.advectionProgram.program.uniforms.uSource.value =
      this.velocity.read.texture;
    this.advectionProgram.program.uniforms.dissipation.value =
      this.velocityDissipation;

    gl.renderer.render({
      scene: this.advectionProgram,
      target: this.velocity.write,
      sort: false,
      update: false,
    });
    this.velocity.swap();

    this.advectionProgram.program.uniforms.dyeTexelSize.value.set(
      1 / this.dyeRes
    );
    this.advectionProgram.program.uniforms.uVelocity.value =
      this.velocity.read.texture;
    this.advectionProgram.program.uniforms.uSource.value =
      this.density.read.texture;
    this.advectionProgram.program.uniforms.dissipation.value =
      this.densityDissipation;

    gl.renderer.render({
      scene: this.advectionProgram,
      target: this.density.write,
      sort: false,
      update: false,
    });
    this.density.swap();

    // Set clear back to default
    gl.renderer.autoClear = true;

    // Update post pass uniform with the simulation output
    this.pass.uniforms.tFluid.value = this.density.read.texture;

    // mesh.rotation.y -= 0.0025;
    // mesh.rotation.x -= 0.005;

    this.pass.uniforms.uTime.value = t * 0.0001;
    const camera = this.camera;
    // console.log(this.mesh);
    // Replace Renderer.render with post.render. Use the same arguments.
    this.post.render({ scene: this.mesh, camera });

    // Draw the canvas on top of the post processing
    this.ctx.drawImage(gl.canvas, 0, 0, this.canvas.width, this.canvas.height);
    //   add blend mode to make all white pixels transparent
    //   ctx.globalCompositeOperation = "difference";

    this.ctx.drawImage(
      this.img,
      this.topleft.x,
      this.topleft.y,
      this.newWidth,
      this.newHeight
    );
    //   create mask if needed
    if (this.topleft.y > 0) {
      this.ctx.fillStyle = "#3D4445";
      this.ctx.fillRect(0, 0, this.canvas.width, this.topleft.y + 4);
      this.ctx.fillRect(
        0,
        this.topleft.y + this.newHeight - 4,
        this.canvas.width,
        this.topleft.y + 8
      );
    }
  }
}
