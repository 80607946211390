import Background from "./Background";

export default class App {
  constructor() {
    console.log("App initialized");
    new Background();

    // get link
    document.querySelector(".mail").addEventListener("click", () => {
      // open mail
      window.open(
        "mailto:gael.hugo@onemore-studio.com?subject=one%20more%20inquiery&body=one%20more%20hello"
      );
    });
  }
}
